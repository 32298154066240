<template>
    <painel titulo="Perfis de Acesso" icone="pi pi-sliders-h" :refreshFunction="obterPerfis">
        <Accordion :multiple="true" :activeIndex="0">
            <AccordionTab header="Associados">
                <perfis-associados
                    :perfis="perfisAssociados"
                    @selecionar="perfisAssociadosSelecionados = $event"
                    @desassociar="desassociar()"
                ></perfis-associados>
            </AccordionTab>
            <AccordionTab header="Disponíveis">
                <perfis-disponiveis
                    :perfis="perfisDisponiveis"
                    @selecionar="perfisDisponiveisSelecionados = $event"
                    @associar="associar()"
                ></perfis-disponiveis>
            </AccordionTab>
        </Accordion>
    </painel>
</template>

<script>
import PerfisAssociados from './PerfisAssociados';
import PerfisDisponiveis from './PerfisDisponiveis';
import UsuariosServices from './services';

export default {
    components: {
        PerfisAssociados,
        PerfisDisponiveis,
    },

    // emits: ['atualizar-tem-regras-associadas'],

    data() {
        return {
            perfisAssociados: [],
            perfisAssociadosSelecionados: [],
            perfisDisponiveis: [],
            perfisDisponiveisSelecionados: [],
        };
    },

    methods: {
        obterPerfis() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPerfis(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.perfisAssociados = response.data.perfisAssociados;
                    this.perfisDisponiveis = response.data.perfisDisponiveis;
                } else {
                    this.perfisAssociados = [];
                    this.perfisDisponiveis = [];
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: 'Erro ao obter perfis de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        desassociar() {
            this.$store.dispatch('addRequest');
            let perfis = {
                usuarioId: this.$route.params.id,
                perfis: this.perfisAssociadosSelecionados,
            };
            UsuariosServices.desassociar(perfis).then((response) => {
                if (response?.success) {
                    this.perfisAssociados = response.data.perfisAssociados;
                    this.perfisDisponiveis = response.data.perfisDisponiveis;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Perfis de acesso desassociados com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterPerfis();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuário',
                        detail: 'Erro ao desassociar perfis de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        associar() {
            this.$store.dispatch('addRequest');
            let perfis = {
                usuarioId: this.$route.params.id,
                perfis: this.perfisDisponiveisSelecionados,
            };
            UsuariosServices.associar(perfis).then((response) => {
                if (response?.success) {
                    this.perfisAssociados = response.data.perfisAssociados;
                    this.perfisDisponiveis = response.data.perfisDisponiveis;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuários',
                        detail: 'Perfis de acesso associados com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterPerfis();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: 'Erro ao associar perfis de acesso',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterPerfis();
    },
};
</script>
