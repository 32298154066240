<template>
    <painel titulo="Usuário" icone="pi pi-key" :refreshFunction="obterUsuario">
        <div class="mb-4">
            <detalhe titulo="Login">{{ usuario?.login }}</detalhe>
            <detalhe titulo="Nome">{{ usuario?.nome }}</detalhe>
            <detalhe titulo="Email">{{ usuario?.email }}</detalhe>
            <detalhe titulo="Tipo">{{ usuario?.tipoDescricao }}</detalhe>
            <detalhe titulo="Status">
                <status :status="usuario?.statusDescr"></status>
            </detalhe>
        </div>
        <btn-inserir></btn-inserir>
        <btn-gerar-senha :usuario="usuario" v-if="usuario?.tipo == 1"></btn-gerar-senha>
        <btn-voltar :route="{ name: 'Usuarios Sol' }"></btn-voltar>
    </painel>
    <perfis></perfis>
</template>

<script>
import UsuariosServices from './services';
import BtnInserir from './BtnInserir';
import Perfis from './Perfis';
import BtnGerarSenha from './BtnGerarSenha.vue';

export default {
    components: {
        BtnInserir,
        Perfis,
        BtnGerarSenha,
    },

    data() {
        return {
            usuario: null,
        };
    },

    methods: {
        obterUsuario() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Usuarios Sol' });
        },
    },

    mounted() {
        this.obterUsuario();
    },
};
</script>
