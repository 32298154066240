<template>
    <Button label="Nova Senha" icon="pi pi-pencil" class="ml-2" @click="gerarSenha()" v-if="$temAcessoView('SOL-001') && usuario.tipo == 1" />
    <Dialog header="Nova Senha" v-model:visible="displayBasic" :style="{ width: '30vw' }">
        <span>
            A nova senha é:
            <h5>{{ senhatxt }}</h5></span
        >
        <template #footer>
            <Button label="Fechar" icon="pi pi-check" @click="closeBasic" autofocus />
        </template>
    </Dialog>
</template>

<script>
import UsuariosServices from './services';

export default {
    props: {
        usuario: {
            type: Object,
        },
    },

    data() {
        return {
            senhatxt: null,
            usuarioid: null,
            displayBasic: false,
            response: null,
        };
    },

    methods: {
        gerarSenha() {
            this.$store.dispatch('addRequest');
            UsuariosServices.gerarSenha(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.senhatxt = response.data.senhaTxt;
                    this.$toast.add({ severity: 'success', summary: 'Usuário', detail: 'Nova senha gerada com sucesso', life: 3000 });
                    this.openBasic();
                }
                this.$store.dispatch('removeRequest');
            });
        },

        openBasic() {
            this.displayBasic = true;
        },
        closeBasic() {
            this.displayBasic = false;
        },
    },
};
</script>
