<template>
    <tabela :data="perfis" dataKey="perfilAcessoId" :globalFilterFields="['codPerfilAcesso', 'descricaoPerfilAcesso']" stateKey="dt-perfisdisponiveis">
        <template #botoes>
            <Button label="Associar Selecionados" class="ml-2" icon="pi pi-link" @click="$emit('associar')" :disabled="perfisSelecionados.length < 1" />
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <Checkbox name="perfis" :value="slotProps.data.perfilAcessoId" v-model="perfisSelecionados" />
                </template>
            </Column>
            <Column field="codPerfilAcesso" header="Código" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.codPerfilAcesso }}</span>
                </template>
            </Column>
            <Column field="descricaoPerfilAcesso" header="Descrição" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.descricaoPerfilAcesso }}
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        perfis: {
            type: Array,
        },
    },

    emits: ['selecionar', 'associar'],

    data() {
        return {
            filters: {},
            perfisSelecionados: [],
        };
    },

    watch: {
        perfisSelecionados() {
            this.$emit('selecionar', this.perfisSelecionados);
        },

        perfis() {
            this.perfisSelecionados = [];
        },
    },
};
</script>
