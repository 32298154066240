import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/usuarios`);
    },

    obterTodosUsuariosSol() {
        return axiosJwt.get(`${api}/usuarios/sol`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/usuarios/${id}`);
    },

    obterPerfis(id) {
        return axiosJwt.get(`${api}/usuarios/${id}/perfis`);
    },

    inserir(usuario) {
        return axiosJwt.post(`${api}/usuarios`, usuario);
    },

    obterPorLogin(login) {
        return axiosJwt.get(`${api}/usuarios/login/${login}`);
    },

    associar(perfis) {
        return axiosJwt.post(`${api}/usuarios/associar`, perfis);
    },

    desassociar(perfis) {
        return axiosJwt.post(`${api}/usuarios/desassociar`, perfis);
    },

    gerarSenha(usuarioId) {
        return axiosJwt.patch(`${api}/usuarios/${usuarioId}/gerarnovasenha`);
    },
};
